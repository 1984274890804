.h-50vh{
    height: 50vh;
}
.h-37vh{
    height: 37vh;
}

.h-0{
    height: 0;
    overflow: hidden;
}

.p-5{
    padding: 1.25rem;
}

.p-0{
    padding: 0;
}

.top-540px{
    top: 540px;
}

.b-t-l-r-15{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.h-40vh{
    height: 40vh;
}
